<!--权限控制-账户信息-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" @submit.native.prevent>
        <el-form-item>
          <el-input
            @change="getData"
            size="mini"
            v-model.trim="filter.val"
            placeholder="关键字搜索"
          >
            <el-button
              slot="prepend"
              icon="el-icon-refresh"
              @click="handleRefresh"
            ></el-button>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getData"
            ></el-button
            >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="handleAdd"
          >新增
          </el-button
          >
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        border
        stripe
        size="mini"
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column
          type="selection"
          align="center"
          width="55"
        ></el-table-column>
        <el-table-column
          type="index"
          header-align="center"
          label="#"
          align="center"
          width="50"
        ></el-table-column>
        <el-table-column
          prop="Name"
          header-align="center"
          label="姓名"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="Mobile"
          align="center"
          header-align="center"
          label="手机号"
        >
        </el-table-column>
        <el-table-column
          prop="Role"
          align="center"
          header-align="center"
          label="角色"
        >
          <template slot-scope="scope">
            {{ roleFormatter(scope.row.Role) }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop='Email' align='center' header-align='center' label='邮箱' width='120'>
    </el-table-column> -->
        <el-table-column
          prop="Status"
          align="center"
          header-align="center"
          label="状态"
        >
          <template slot-scope="scope">
            <span v-html="statusFormatter(scope.row.Status)"></span>
          </template>
        </el-table-column>
        <el-table-column prop="Remark" header-align="center" label="备注">
        </el-table-column>
        <el-table-column align="center" label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <i
              title="重置密码"
              @click="handleReset(scope.row)"
              class="opt fa fa-window-restore"
            />
            <i
              title="编辑"
              @click="handleEdit(scope.$index, scope.row)"
              class="opt el-icon-edit"
            />
            <i
              title="删除"
              @click="handleDel(scope.$index, scope.row)"
              class="opt el-icon-delete"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-pagination
        background
        @size-change="handleSizeChange"
        :current-page.sync="filter.page"
        @current-change="handleCurrentChange"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      @close="handlerClose"
    >
      <!--编辑界面-->
      <template>
        <el-form
          :model="formData"
          label-width="80px"
          :rules="formRule"
          ref="formData"
          size="mini"
        >
          <el-col :span="12">
            <el-form-item prop="Name" label="姓名">
              <el-input
                @change="hasChange"
                v-model.trim="formData.Name"
              ></el-input>
            </el-form-item>
            <el-form-item prop="Mobile" label="手机号">
              <el-input
                @change="hasChange"
                v-model.trim="formData.Mobile"
              ></el-input>
            </el-form-item>
            <el-form-item prop="PlainPassword" label="密码">
              <el-input
                @change="hasChange"
                v-model.trim="formData.PlainPassword"
              ></el-input>
            </el-form-item>
            <el-form-item prop="Status" label="状态">
              <el-radio
                @change="hasChange"
                v-model.trim="formData.Status"
                :label="1"
              >启用
              </el-radio
              >
              <el-radio
                @change="hasChange"
                v-model.trim="formData.Status"
                :label="99"
              >禁用
              </el-radio
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Role" label="角色">
              <el-select
                @change="hasChange('Role')"
                v-model.trim="formData.Role"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in role"
                  :label="item.name"
                  :key="item.sign"
                  :value="item.sign"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="EntityId" label="所属单位" v-if="hasEntity">
              <el-cascader
                v-model="formData.EntityId"
                placeholder="所属单位"
                @change="hasChange"
                :options="customerTree"
                :props="orgProps"
                clearable
                filterable
              />
            </el-form-item>
            <el-form-item prop="Remark" label="备注">
              <el-input
                @change="hasChange"
                v-model.trim="formData.Remark"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form>
        <div slot="footer">
          <el-button size="small" @click.native="dlg.visible = false"
          >取消
          </el-button
          >
          <el-button type="primary" size="small" @click.native="handleSubmit"
          >提交
          </el-button
          >
        </div>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import {mapState} from "vuex";
import {mobileValidator} from "@/util/validate";
import {passwordValid} from "@/util/passwordValid";
import {statusFormatter, loadData} from "@/util/index";

export default {
  data() {
    return {
      user: [],
      changeState: false,
      index: 0, // 记录更新index
      loading: false,
      datalist: [],
      role: [],
      dlg: {
        visible: false,
        title: "",
        type: 1,
      },
      filter: {
        val: null,
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50],
      },
      formData: {},
      formRule: {
        Name: [{required: true, message: "名称不可为空", trigger: "blur"}],
        PlainPassword: [{required: true, message: "密码不可为空", trigger: "blur"}],
        Mobile: [
          {required: true, trigger: "blur", validator: mobileValidator},
        ],
      },
    };
  },
  computed: {
    ...mapState({
      orgProps: (state) =>
        Object.assign({}, state.props, {label: "Org", checkStrictly: true}),
    }),
    ...mapState(["props", "customerTree","clientHeight"]),
    // 是否需要所属单位
    hasEntity() {
      if (this.formData.Role) {
        // 用户类型 typ 1 系统类型；2 经销代理；3 行政单位；4 餐饮单位；5 维保人员
        const roleType = Number(
          this.role.find((item) => item.sign === this.formData.Role).typ
        );
        return (
          roleType === 2 || roleType === 3 || roleType === 4 || roleType === 5
        );
      } else {
        return false;
      }
    },
  },
  created() {
    this.$get("admin/listRole")
      .then((res) => {
        this.role = res;
      })
      .catch(() => {
      });
    this.$get('admin/listBasicDataOfUser').then(res => {
      this.user = res.User
      this.getData();
    })

  },
  methods: {
    /**
     * @description 变量值改变
     * @param {String} name 变量名称
     */
    hasChange(name) {
      this.changeState = true;
      // 如果角色变更，则清空
      if (name === "Role") {
      }
    },
    getData: function () {
      this.loading = true;
      this.datalist.content = this.user;
      if (this.filter.val) {
        console.log(111)
        this.datalist.content = this.user.filter(
          (v, _) =>
            v.Name.indexOf(this.filter.val) !== -1 ||
            v.Mobile.indexOf(this.filter.val) !== -1
        );
      }
      this.datalist.total = this.datalist.content.length;
      this.$set(
        this.datalist,
        "content",
        this.datalist.content.slice(
          (this.filter.page - 1) * this.filter.size,
          this.filter.page * this.filter.size
        )
      );
      this.loading = false;
    },
    statusFormatter(status) {
      return statusFormatter("ENABLE", status);
    },
    roleFormatter(key) {
      for (let i = 0, len = this.role.length; i < len; i++) {
        if (this.role[i].sign === key) {
          return this.role[i].name;
        }
      }
      return "";
    },
    handleRefresh() {
      this.filter.page = 1;
      this.filter.val = null;
      loadData(
        this,
        (res) => {
          this.$store.dispatch("setUser", res).then(() => {
            this.getData();
          });
        },
        4
      );
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size;
      this.getData();
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page;
      this.getData();
    },
    handleAdd: function () {
      // this.$get("admin/queryRandomPlainPassword").then((res) => {
      //   console.log(res)
      // });
      this.dlg.title = "新增";
      this.dlg.type = 1;
      this.dlg.visible = true;
      this.formData = {Status: 1};
    },
    handleEdit: function (index, row) {
      this.dlg.title = "编辑";
      this.dlg.type = 1;
      this.dlg.visible = true;
      this.index = index;
      this.formData = Object.assign({}, row);
      this.formData.EntityId = row.EntityId
        ? row.EntityId.split("/")
          .filter((x) => !!x)
          .map((item) => Number(item))
        : [];
    },
    handleReset(row) {
      this.$confirm("确认重置密码吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          this.$get("admin/reset", {uid: row.Id}).then(() => {
            this.getData();
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleDel: function (index, row) {
      this.$confirm("确认删除该记录吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          const para = {id: row.Id};
          this.$get("admin/delCaUser", para).then(() => {
            this.datalist.content.splice(index, 1);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleSubmit: function () {
      if (!this.changeState) {
        this.dlg.visible = false;
        return;
      }
      this.$refs.formData.validate((valid) => {
        if (valid) {
          const passwordStr = passwordValid(this.formData.PlainPassword);
          // const passwordStr = true;
          if (passwordStr !== true) {
            this.$notify.error({
              title: "失败",
              message: passwordStr,
              duration: 3000,
            });
          } else {
            this.$confirm("确认提交吗？", "提示", {})
              .then(() => {
                const para = Object.assign({}, this.formData);
                // console.log(para, para.EntityId);
                para.EntityId = para.EntityId ? para.EntityId.join("/") : null;
                this.$post("admin/saveCaUser", para).then(() => {
                  this.dlg.visible = false;
                });
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
      });
    },
    handlerClose: function (index, row) {
      if (this.changeState) {
        this.changeState = false;
        this.handleRefresh();
      }
      if (this.$refs["formData"]) {
        this.$refs["formData"].resetFields();
      }
    },
  },
  watch: {
    "formData.EntityId": {
      handler: function (val, oldVal) {
        console.log("EntityId", val, oldVal);
      },
      deep: true,
    },
  },
};
</script>
